*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.header {
    margin: auto;
    height: 720px;
    background-image: url('../../assets/img/banner.jpg');
    background-size: auto;
    background-repeat: no-repeat;
}

.navbar {
    padding: 10 0px;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #212529;
    z-index: 11;
}


.navbar-logo h2{
    color: #fff;
    font-size: 1.3em;
    font-family: 'Martel', serif;
}

.nav-menu{
    display: flex;
    align-items: center;
}

.nav-list {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    justify-content: space-between;
    /* width: 500px; */
}

.nav-list li {
    list-style: none;
    /* margin-left: 20px; */
}

.nav-list li a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    margin: 0 20px;
}

.bar-menu {
    display: none;
}

.banner {
    position: relative;
    top: 250px;
    font-size: 1.5em;
    color: #fff;
}
.banner_title {
    font-family: 'Martel', serif;
}
.banner_paragraph {
    font-family: "Montserrat", Helvetica;
}


@media (width < 769px) {
    .header {
        height: 720px;
        background-image: url('../../assets/img/banner.jpg');
        background-position: center;
        background-repeat: no-repeat;
    }
    .navbar-logo h2{
        font-size: 1em;
    }
    .nav-list {
        display: none;
    }
    .nav-list.active {
        padding-top: 100px;
        text-align: center;
        display: block;
        position: fixed;
        top: 80px;
        right: 0px;
        width: 100%;
        height: 100vh;
        transition: 0.9s ease-in-out;
        background-color: #fff;
        opacity: 1;
        z-index: 1;
    }
    .nav-list li {
        margin-bottom: 20px;
    }

    .nav-list li a {
        color: #000;
    }

    .bar-menu {
        display: block;
    }
    .bar-menu i {
        font-size: 1.8em;
        color: #fff;
    }

    .banner {
        top: 30%;
    }
    .banner-1{
        margin-bottom: 200px;
    }
    .banner_title {
        font-size: 1.3em;
    }
    .banner_paragraph {
        font-size: 0.6em;
        text-align: left;
    }
}

@media (width > 767px) {
    .bar-menu i {
        font-size: 1.5em;
        color: #fff;
    }
    .banner-1{
        margin: auto 0;
    }
}