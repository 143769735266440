.form-card {
    margin-left: auto;
    max-width: 390px;
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 20px;
}
.form-card h4 {
    color: #000;
    text-align: center;
    margin-bottom: 20px;
}

form input, form select {
    margin-bottom: 10px;
}

.btn-regis {
    color: #fff;
    width: 100%;
    background-color: #ffc107;
}