#pricing {
    padding: 150px 0 70px;
}
.pricing-header {
    text-align: center;
    max-width: 800px;
    /* background-color: red; */
    margin: auto;
    padding: 20px 0;
}
.pricing-header h4{
    font-family: 'Martel', serif;
    font-size: 2em;
}
.pricing-header p {
    font-family: "Montserrat", Helvetica;
}
.pricing-box {
    max-width: 300px;
    min-height: 508px;
    border-radius: 8px;
    border: 2.5px solid #EFF1F3;
    padding: 30px 45px;
    margin-bottom: 10px auto;
    font-family: 'Inter', sans-serif;
}
.pricing-box-header h4{
    font-size: 2.2em;
    margin: 0;
}
.pricing-box-price {
    padding: 20px 0;
}
.currency{
    font-size: 25px;
    margin-right: 2px;
}
.price{
    font-size: 2em;
    font-weight: bolder;
}
.pricing-box-facility{
    padding: 5px 0;
}
.pricing-box-facility ul{
    padding-left: 0;
}
.pricing-box-facility ul li{
    list-style: none;
    margin-left: 0;
}
.red{
    color: rgb(255, 0, 0);
}
.pricing-box-facility ul li i{
    margin-right: 10px;
}
.pricing-box-facility p span {
    font-weight: 700;
    font-size: 1.3em;
}

#mailbox h3{
    margin-bottom: 2em;
    font-family: 'Martel', serif;
    margin: 0;
}

#mailbox p {
    font-family: "Montserrat", Helvetica;
    line-height: 1.5;
    text-align: justify;
    max-width: 800px;
}

.mail-box{
    padding: 30px 20px;
    border: 2.5px solid #EFF1F3;
    max-width: 340px;
    border-radius: 8px;
    margin: auto;
}
@media (width < 768px){
    #pricing {
        padding: 200px 0 0px;
    }
    .pricing-box {
        margin: 10px auto;
    }

}
@media (width > 767px){
    #pricing {
        padding: 100px 0;
    }
    .pricing-box {
        margin: 10px auto;
        max-width: 370px;
    }

}
@media (width > 1023px){
    .mailbox-content {
        margin: auto 0;
    }
}


