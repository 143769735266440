.footer1{
    background-color: #151515;
    padding: 10px;
    font-family: 'Martel', serif;
}
.footer1 h3, .footer1 h5 {
    color: #fff;
}
.footer1 ul {
    padding: 0;
    margin-top: 30px;
}
.footer1 ul li {
    list-style: none;
    margin-right: 20px;
}
.footer1 a {
    color: #696969;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}
.footer2 {
    background: #156797;
    min-height: 90px;
    display: flex;
    align-items: center;
    color: #fff;
}
.footer2 p {
    margin: auto 0;
}
.contact {
    margin-bottom: 10px;
}

@media (width < 768px) {
    .footer1 {
        padding: 50px 0;
    }
    .footer1 ul li {
        margin-bottom: 10px;
    }
}
@media (width > 767px) {
    .footer1 {
        padding: 90px 0;
    }
    .footer1 h3 {
        margin-bottom: 20px;
    }
    .footer1 ul {
        display: inline;
    }
    .footer1 ul li {
        display: inline;
    }
}
@media (width > 1023px) {
    .footer1 {
        padding: 100px 0;
    }
}